<template>
    <div class="pr">
        <div class="mode-title">
            <div class="uf uf-ac uf-pc">
                <i class="el-icon-edit mr-2"></i>
                <span>自定义组合</span>
            </div>
        </div>
        <div class="left-flows uf uf-ver">
            <div class="f-list uf-f1 uf uf-pc pr" v-for="(item, index) in flowsData" :key="index" @click="selFlow(item)">
                <div class="icon-box uf uf-pc uf-ac">
                    <i :class="`iconfont icon-${item.icon}`" :style="{color: item.color}"></i>
                    <div class="f-name ml-2">{{ item.itemName }}</div>
                </div>
                <div v-if="flowsSelData.some(v => v.id === item.id)" class="checked pr" style="background: rgb(0, 133, 55)">
                    <i class="el-icon-check"></i>
                </div>
            </div>
        </div>
        <div class="main-wrap">
            <div class="dec-box pr uf uf-pc" v-if="flowsSelData.length > 0">
                <div class="">
                    <div class="service-box uf" v-loading="loadingAdv">
                        <div class="item pr" :class="{on: curFlowIndex === index && selShow}" :style="{width: `calc(78vw / ${flowsData.length})`}"
                             v-for="(item, index) in flowsSelData" :key="index" @mouseenter="showSelBox(index, item.itemCode)"
                             @mouseleave="hideSelBox(index, item.id)">
                            <div class="f-list uf-f1 uf uf-pc">
                                <div class="icon-box uf uf-pc uf-ac">
                                    <i :class="`iconfont icon-${item.icon}`" :style="{color: item.color}"></i>
                                    <div class="f-name ml-2">{{ item.itemName }}</div>
                                </div>
                            </div>
                            <div class="li-wrap">
                                <div v-if="item.id && item.sels.length > 0" class="uf hi100 uf-ac uf-ver uf-pc">
                                    <el-image fit="cover" :src="$cons.SERVER_PATH + item.sels[0].logoUrl"
                                              class="img">
                                        <div slot="error" class="wi100 hi100">
                                            <img :src="item.img" alt="" width="100%" height="100%"/>
                                        </div>
                                    </el-image>
                                    <div class="wi100 mt-2 pl-2 pr-2">
                                        <div class="fwb f18 toe-auto wi100">{{
                                            item.sels[0].serviceName || '-'
                                            }}
                                        </div>
                                        <div class="uf uf-ac uf-pc op-7 wi100 mt-1">
                                            <div class="iconfont icon-company-fill"></div>
                                            <div class="ml-1 toe-auto tal" style="max-width: calc(100% - 30px)">
                                                {{ item.sels[0].enterpriseName || '-' }}
                                            </div>
                                        </div>
                                        <!--                                <div class="u-line-2 lh1 describe u-m-t-6">服务简介：{{ item.itemDesc || '无' }}</div>-->
                                        <!--                        <el-button type="primary" plain size="small" class="u-m-t-10">立即使用</el-button>-->
                                    </div>
                                </div>
                                <div v-else class="hi100 uf uf-ver uf-pc uf-ac">
                                    <img src="../../../assets/img/empty.png" alt="" width="150"/>
                                    <div>未选择服务</div>
                                </div>
                                <div class="opr-btn uf" v-if="item.id && curFlowIndex === index && selShow">
                                    <div class="bt uf-f1 uf uf-ac uf-ver uf-pc" @click="moveLeft(index)">
                                        <i class="el-icon-back"></i>
                                        <div class="mt-1">前移</div>
                                    </div>
                                    <div class="bt uf-f1 uf uf-ac uf-ver uf-pc" @click="moveRight(index)">
                                        <i class="el-icon-right"></i>
                                        <div class="mt-1">后移</div>
                                    </div>
                                    <div class="bt uf-f1 uf uf-ac uf-ver uf-pc" @click="deleteFlowItem(item)">
                                        <i class="el-icon-delete"></i>
                                        <div class="mt-1">删除</div>
                                    </div>
                                </div>
                                <div class="check-state pr" v-if="item.sels.length > 1" style="background: rgb(0,186,218)">
                                    <span>+{{ Number(item.sels.length) - 1 }}</span>
                                </div>
                            </div>
                            <div class="arrow uf uf-ac" v-if="index !== flowsSelData.length - 1">
                                <div class="h-line"></div>
                                <i class="iconfont icon-jiantou-you"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="order uf uf-ver uf-pc uf-ac" @click="publish" v-loading="publishLoading" element-loading-text="发布中…" element-loading-background="rgba(0, 0, 0, 0.7)">
                    <i class="el-icon-right"></i>
                    <div>组合发布</div>
                </div>
            </div>
            <div v-else class="wi100 hi100 uf uf-pc uf-ac empty_1">
                <div class="tac">
                    <img src="../../../assets/img/empty.png" alt="" width="200"/>
                    <div class="mt-3 t">组合为空，请点击左侧流程节点添加</div>
                </div>
            </div>

            <div class="sel-box" :class="{show: selShow}" @mouseenter="selShow = true" @mouseleave="selLeave" v-if="flowsSelData.length > 0">
                <div class="sel-wrap wi100 hi100" v-loading="loadingSel">
                    <div class="uf uf-ac" style="min-height: 60px">
                        <div class="fwb">已选：</div>
                        <div v-if="curFlowIndex > -1 && flowsSelData[curFlowIndex].sels.length > 0" class="sels-box uf">
                            <div v-for="(item, index) in flowsSelData[curFlowIndex].sels" class="sel-li tac pr toe-auto" :key="index">
                                {{ item.serviceName}}
                                <div class="opr-btn3 uf">
                                    <div class="bt uf-f1 uf uf-ac uf-pc" @click="toService(item.id)">
                                        <i class="el-icon-view"></i>
                                        <div class="ml-1">详情</div>
                                    </div>
                                    <div class="bt uf-f1 uf uf-ac uf-pc" @click="delService(item)">
                                        <i class="el-icon-delete"></i>
                                        <div class="ml-1">删除</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="hi100 uf uf-pc uf-ac">
                            <img src="../../../assets/img/empty.png" alt="" width="60"/>
                            <div>未选择服务，请从下面的服务中选择</div>
                        </div>
                        <div class="ml-3">
                            <el-button type="default" @click="reDefault">恢复默认</el-button>
                            <el-button type="primary" @click="clear">清空</el-button>
                        </div>
                    </div>
                    <div class="items mt-3">
                        <div class="item uf pr" v-for="(item, index) in serviceFlowList" :key="index">
                            <div v-if="item.checked" class="checked pr" style="background: rgb(0, 133, 55)">
                                <i class="el-icon-check"></i>
                            </div>
                            <div class="img">
                                <el-image fit="cover" :src="$cons.SERVER_PATH + item.logoUrl" class="wi100 hi100">
                                    <div slot="error" class="wi100 hi100">
                                        <img :src="item.img" alt="" width="100%" height="100%"/>
                                    </div>
                                </el-image>
                            </div>
                            <div class="uf-f1 u-m-l-15 u-flow-hidden">
                                <div class="fwb f16 toe">{{ item.serviceName || '-' }}</div>
                                <div class="uf uf-ac company u-m-t-10">
                                    <div class="iconfont icon-company-fill"></div>
                                    <div class="uf-f1 ml-1 toe">{{ item.enterpriseName || '-' }}</div>
                                </div>
                                <div class="u-line-2 lh1 describe u-m-t-6">服务简介：{{ item.itemDesc || '无' }}</div>
                                <!--                        <el-button type="primary" plain size="small" class="u-m-t-10">立即使用</el-button>-->
                            </div>
                            <div class="opr-btn2 uf">
                                <div class="bt uf-f1 uf uf-ac uf-ver uf-pc" @click="toService(item.id)">
                                    <i class="el-icon-view"></i>
                                    <div class="mt-1">详情</div>
                                </div>
                                <div v-if="item.checked" class="bt uf-f1 uf uf-ac uf-ver uf-pc" @click="delService(item)">
                                    <i class="el-icon-delete c-del"></i>
                                    <div class="mt-1 c-del">从已选中删除</div>
                                </div>
                                <div v-else class="bt uf-f1 uf uf-ac uf-ver uf-pc" @click="selectService(item)">
                                    <i class="el-icon-circle-check"></i>
                                    <div class="mt-1">选择</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tac wi100 mt-4 mb-2">
                        <el-pagination
                            background
                            @size-change="sizeChangeHandle"
                            @current-change="currentChangeHandle"
                            :current-page="pageIndex"
                            :page-size="pageSize"
                            :total="totalCount"
                            layout="total, prev, pager, next, jumper">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'trade-detail',
    props: {
        trade: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            serviceList: [],
            serviceFlowList: [],
            isDefault: false,
            loadingAdv: false,
            loadingSel: false,
            selShow: false,
            isSel: false,
            publishLoading: false,
            planList: [],
            curFlowIndex: -1,
            curFlowCode: '',
            colors: ['#409EFF', '#ff6900', '#008638', '#9200e5', '#c5a500', '#ff004c', '#409EFF', '#409EFF'],
            leftSels: [],
            flowsData: [],
            flowsSelData: [],
            curTrade: {},
            curTradeIndex: 0,
            pageIndex: 1,
            pageSize: 20,
            totalCount: 0,
            totalPage: 0,
            formData: {
                serviceComposeName: '',
                serviceComposeBatchEntityList: []
            }
        }
    },
    created() {
        this.getServiceItemList()
        // this.getFlowServiceList()
        // this.getPlanList()
    },
    watch: {
        trade() {
            this.getServiceItemList()
        },
        curFlowCode(val) {
            this.getServiceList(val)
        }
    },
    methods: {
        showSelBox(index, code) {
            this.selShow = true
            this.curFlowIndex = index
            this.curFlowCode = code
        },
        hideSelBox(index, id) {
            this.selShow = false
        },
        selFlow(item) {
            if(!this.flowsSelData.some(v => v.id === item.id)) this.flowsSelData.push(item)
        },
        moveLeft(index) {
            if (index === 0) return
            this.flowsSelData[index] = this.flowsSelData.splice(index - 1, 1, this.flowsSelData[index])[0]
            this.curFlowIndex = -1
            this.selShow = false
        },
        moveRight(index) {
            if (index === this.flowsSelData.length - 1) return
            this.flowsSelData[index] = this.flowsSelData.splice(index + 1, 1, this.flowsSelData[index])[0]
            this.curFlowIndex = -1
            this.selShow = false
        },
        selectService(item) {
            let index = this.flowsSelData[this.curFlowIndex].sels.findIndex(v => v.id === item.id)
            if (index === -1) {
                this.flowsSelData[this.curFlowIndex].sels.push(item)
                item.checked = true
            }
        },
        delService(item) {
            this.serviceFlowList.find(v => v.id === item.id).checked = false
            item.checked = false
            let index = this.flowsSelData[this.curFlowIndex].sels.findIndex(v => v.id === item.id)
            this.flowsSelData[this.curFlowIndex].sels.splice(index, 1)
        },
        deleteFlowItem(item) {
            let index = this.flowsSelData.findIndex(v => v.id === item.id)
            this.flowsSelData.splice(index, 1)
            this.curFlowIndex = -1
            this.selShow = false
        },
        selLeave() {
            this.selShow = false
        },
        reDefault() {
            let a = this.flowsSelData[this.curFlowIndex].esProductIndex
            this.flowsSelData[this.curFlowIndex].sels = [
                {...a}
            ]
            this.serviceFlowList.forEach(v => {
                v.checked = a.id === v.id
            })
        },
        clear() {
            this.flowsSelData[this.curFlowIndex].sels = []
            this.serviceFlowList.forEach(v => {
                v.checked = false
            })

        },
        deleteFlowService(index) {
            this.serviceList[index] = {}
        },
        toService(id) {
            let page = this.$router.resolve({name: 'service', query: {id: id, isProvider: false}})
            window.open(page.href, '_blank')
        },
        publish() {
            let arr = []
            this.flowsSelData.forEach(v => {
                arr = arr.concat(v.sels)
            })
            if (arr.length < 1) {
                this.$message.error('请至少选择一个产业链服务项！')
                return
            }
            this.formData.serviceComposeBatchEntityList = arr
            this.$prompt('请输入组合名称', '组合名称', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                    inputValidator: (value) => {
                        if(!value) {
                            return '名称不能为空！'
                        }
                    },
                inputErrorMessage: '名称不能为空！'
            }).then(({ value }) => {
                this.publishLoading = true
                this.formData.serviceComposeName = value
                this.$http({
                    url: this.$http.adornUrl(this.$api.PUB.SERVICECOMPOSEMAIN),
                    method: 'post',
                    data: this.$http.adornData(this.formData)
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        this.$message.success('发布成功！')
                        setTimeout(() => {
                            window.open('https://www.somfgs.cn/manage-back/#/product-product', '_blank')
                        },1200)
                    }
                    this.publishLoading = false
                })
            })
        },
        // 获取流程服务
        getServiceItemList() {
            this.loadingAdv = true
            // let filter = "itemCode:"+ itemCode + "*,serviceMethod:" + serviceMethod
            this.$http({
                url: this.$http.adornUrl(this.$api.CF.SERVICE_ITEM.LISTITEM),
                methods: 'get',
                params: this.$http.adornParams({
                    // 'startPage': 0,
                    // 'pageSize': 7,
                    'itemCode': Number(this.trade) + 1 > 9  ? Number(this.trade) + 1 : `0${Number(this.trade) + 1}`
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    // this.serviceList = this.handleTradeImg(data.result.list || [], 4)
                    // this.serviceList[2] = {}
                  let list = data.list.filter((rowData)=>{
                    return rowData.esProductIndex !=null && `0${Number(this.trade) + 1}` != rowData.itemCode
                  })
                    list.forEach((v, i) => {
                        v.color = this.colors[i]
                        v.sels = []
                      if(v.esProductIndex && v.esProductIndex.id) {
                            v.esProductIndex.productName = v.esProductIndex.serviceName
                        }
                        v.sels.push(v.esProductIndex)
                    })
                    this.flowsData = this.handleTradeImg(list, 7)
                    // this.getServiceList()
                }
                this.loadingAdv = false
            })
        },
        // 获取选择服务
        getServiceList(code) {
            this.loadingSel = true
            // let filter = "itemCode:"+ itemCode + "*,serviceMethod:" + serviceMethod
            this.$http({
                url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.SOLRLIST),
                methods: 'get',
                params: this.$http.adornParams({
                    'startPage': 0,
                    'pageSize': 8,
                    'itemCode': code
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.serviceFlowList = this.handleTradeImg(data.result.list || [], 4)
                    let arr = this.flowsSelData[this.curFlowIndex].sels
                    this.serviceFlowList.forEach(v => {
                        v.checked = arr.some(a => a.id === v.id)
                        v.productName = v.serviceName
                    })
                    this.totalCount = data.result.total || 0
                    this.totalPage = Math.ceil(data.result.total / this.pageSize) || 1
                } else {
                    this.dataList = []
                    this.totalCount = 0
                    this.totalPage = 1
                }
                this.loadingSel = false
            })
        },
        getPlanList() {
            // let filter = "itemCode:"+ itemCode + "*,serviceMethod:" + serviceMethod
            this.$http({
                url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.SOLRLIST, 'COMPONENT'),
                methods: 'get',
                params: this.$http.adornParams({
                    'startPage': 0,
                    'pageSize': 4,
                    'serviceLabel': 'REPORT',
                    'itemCode': `0${Number(this.trade) + 1}`,
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.planList = this.handleTradeImg(data.result.list || [], 4)
                }
            })
        },
        handleTradeImg(list, n) {
            if (list.length < 1) return []
            let arr = list
            arr.forEach((item, index) => {
                let num = index % n
                if (num === 0) num = n
                item.img = require(`@/assets/img/trades/${item.itemCode.slice(0, 2)}/img_${num}.jpg`)
            })
            return arr
        },
        handleServiceImg(list, n) {
            if (list.length < 1) return []
            let arr = list
            let t = {}
            arr.forEach((item, index) => {
                t[`i${item.itemCode}`] = 0
            })
            arr.forEach((item) => {
                t[`i${item.itemCode}`]++
                let num = t[`i${item.itemCode}`] % n
                if (num === 0) num = n
                item.img = require(`@/assets/img/trades/${item.itemCode}/img_${num}.jpg`)
            })
            return arr
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getServiceList()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getServiceList()
        }
    }
}
</script>

<style scoped lang="scss">
.trade-detail {
    .empty_1 {
        min-height: 54vh;
        .t {
            font-size: 1vw;
            color: #fff;
            padding: 15px 20px;
            text-shadow: 0 0 10px rgba(0,0,0,.6);
            background: rgba(0,0,0,.4);
        }
    }
    .sels-box {
        .sel-li {
            padding: 10px 20px;
            border: 1px dashed #333;
            margin-right: 20px;
            font-weight: bold;
            font-size: 15px;
            cursor: pointer;
            transition: all .3s ease;
            overflow: hidden;
            max-width: 260px;
            min-width: 160px;
            .opr-btn3 {
                position: absolute;
                left: 0;
                width: 100%;
                top: 0;
                height: 100%;
                background: linear-gradient(bottom, rgba(0, 0, 0, .4), rgba(0, 0, 0, .7));
                z-index: 999;
                transform: translateY(100%);
                transition: all .3s ease;
                font-size: 13px;
                font-weight: normal;
                .bt {
                    color: white;

                    &:hover {
                        background: rgba(0, 0, 0, .7);
                    }

                    i {
                        font-size: 16px;
                    }
                    &:last-child {
                        color: #ffd400;
                    }
                }
            }

            &:hover {
                border: 1px solid #000;
                .opr-btn3 {
                    height: 100%;
                    transform: translateY(0);
                }
            }
        }
    }

    .mode-title {
        width: 210px;
        line-height: 50px;
        color: white;
        background: #E87600;
        transform: skewX(-30deg);
        text-align: center;
        transition: all 0.3s ease;
        margin: 0 auto 2vw;
        div {
            transform: skewX(30deg);

            i {
                font-size: 1.2vw;
            }
        }
    }

    margin-top: -50px;

    .switch-wrap {
        //position: sticky;
        //left: 0;
        //bottom: 10px;
        //width: 100%;
        //margin-top: -6vw;
        margin-top: 3vw;

        .switch-mode {
            width: 22vw;
            height: 3vw;
            margin: 20px auto;
            border-radius: 2vw;
            background: rgba(255, 255, 255, .8);
            backdrop-filter: blur(6px);
            color: #0d9aff;
            cursor: pointer;

            &:hover {
                background: #fff;
            }

            div {
                font-size: 1vw;
            }

            i {
                font-size: 1.2vw;
            }
        }
    }

    .check-state {
        position: absolute;
        width: 4.6vw;
        height: 4.6vw;
        border-radius: 50%;
        right: -2.3vw;
        top: -2.3vw;
        color: white;

        span {
            position: absolute;
            left: 1vw;
            bottom: .7vw;
            font-size: 1vw;
        }
    }

    .opr-btn {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        height: 50%;
        background: linear-gradient(bottom, transparent, rgba(255, 255, 255, .8), #fff);

        .bt {
            &:hover {
                background: #fff;
            }

            i {
                font-size: 1.6vw;
            }

            &:last-child {
                color: #d21900;
            }
        }
    }

    .order {
        width: 7vw;
        //background: rgba(0, 149, 255, 0.6);
        background: rgba(210, 25, 0, .7);
        cursor: pointer;
        transition: all .3s ease;
        color: white;

        i {
            font-size: 2vw;
        }

        div {
            font-size: 1vw;
            margin-top: 15px;
        }

        &:hover {
            background: rgba(210, 25, 0, 1);
        }
    }

    .sel-box {
        background: rgba(255, 255, 255, .8);
        backdrop-filter: blur(6px);
        //min-height: 12vw;
        max-height: 0;
        overflow: hidden;
        width: 100%;
        //margin-left: 2vw;
        transition: all .3s ease;

        .sel-wrap {
            padding: 1vw 1.5vw;
            //background: rgba(255, 255, 255, .5);
        }
    }

    .show {
        max-height: 50vw;
        //overflow: auto;
    }

    .items {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(4, 1fr);

        .item {
            background-color: #fff;
            padding: 15px;
            box-sizing: border-box;
            border-radius: 8px;
            cursor: pointer;
            overflow: hidden;
            .checked {
                position: absolute;
                width: 4vw;
                height: 4vw;
                border-radius: 50%;
                right: -2vw;
                top: -2vw;
                color: white;
                background: rgb(0, 133, 55);
                i {
                    position: absolute;
                    left: .6vw;
                    bottom: .6vw;
                    font-size: 1.1vw;
                }
            }
            .img {
                width: 33%;
                aspect-ratio: 1.2;
                border-radius: 8px;
                overflow: hidden;
            }

            .company {
                font-size: 14px;
                color: #333;
            }

            .describe {
                font-size: 12px;
                color: #666;
            }

            .opr-btn2 {
                position: absolute;
                left: 0;
                width: 100%;
                top: 0;
                height: 100%;
                background: linear-gradient(bottom, rgba(0, 0, 0, .4), rgba(0, 0, 0, .7));
                z-index: 999;
                transform: translateY(100%);
                transition: all .3s ease;
                //overflow: ;
                .bt {
                    color: white;

                    &:hover {
                        background: rgba(0, 0, 0, .7);
                    }

                    i {
                        font-size: 1.6vw;
                    }
                    &:last-child {
                        color: #00ceff;
                    }
                }
                .c-del {
                    color: #ffd400;
                }
            }

            &:hover {
                box-shadow: 0 0 15px rgba(0, 0, 0, .2);

                .opr-btn2 {
                    height: 100%;
                    transform: translateY(0);
                }
            }
        }
    }

    .service-box {
        min-height: 10vw;
        .item {
            //background-color: #fff;
            min-height: 10vw;
            //padding: 15px;
            //width: calc(78vw / 7);
            box-sizing: border-box;
            cursor: pointer;
            border-right: 1px dashed #0d9aff;
            transition: all .3s ease;
            //overflow: hidden;
            position: relative;
            background: rgba(255, 255, 255, 0.6);
            -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
            .arrow {
                position: absolute;
                top: -2vw;
                left: 9.5vw;
                .h-line {
                    height: 3px;
                    background: #ffdd35;
                    width: 3vw;
                    margin-right: -1vw;
                    box-shadow: 0 0 5px rgba(0,0,0,.4);
                }
                .iconfont {
                    font-size: 1.6vw;
                    color: #ffdd35;
                    text-shadow: 0 0 5px rgba(0,0,0,.4);
                }
            }
            .li-wrap {
                width: 100%;
                height: 100%;
                overflow: hidden;
                position: relative;
            }

            .img {
                width: 5vw;
                height: 4vw;
                border-radius: 6px;
                overflow: hidden;
            }

            &:hover {
                background: rgba(255, 255, 255, .8);
            }

            .f-list {
                position: absolute;
                top: -2.6vw;
                left: 50%;
                transform: translateX(-50%);
                z-index: 9;

                .icon-box {
                    width: 8vw;
                    height: 3.5vw;
                    background: #fff;
                    border-radius: 1vw;
                    box-shadow: 0 0 20px rgba(0, 0, 0, .2);

                    .iconfont {
                        font-size: 1.6vw;
                    }
                }

                .f-name {
                    font-size: 1vw;
                    font-weight: bold;
                    color: #333;
                    //margin-top: 1vw;
                }
            }
        }

        .on {
            background: rgba(255, 255, 255, .8);
        }
    }

    .main-wrap {
        margin: 1vw 2vw 0 11vw;
        padding: 3.3vw 1.5vw 1.5vw;
        border: 2px dashed #ffffff;
        min-height: 60vh;
    }

    .left-flows {
        position: absolute;
        z-index: 9;
        left: 1.3vw;
        top: 4vw;

        .f-list {
            margin-bottom: 1vh;
            box-shadow: 0 0 20px rgba(0, 0, 0, .2);
            border-radius: .5vw;
            overflow: hidden;
            .icon-box {
                width: 8vw;
                height: 3.5vw;
                background: #fff;
                cursor: pointer;

                .iconfont {
                    font-size: 1.6vw;
                }

                &:hover {
                    background: #efefef;
                }
            }

            .f-name {
                font-size: 1vw;
                font-weight: bold;
                color: #333;
                //margin-top: 1vw;
            }
            .checked {
                position: absolute;
                width: 3vw;
                height: 3vw;
                border-radius: 50%;
                right: -1.5vw;
                bottom: -1.5vw;
                color: white;
                background: rgb(0, 133, 55);
                i {
                    position: absolute;
                    left: .4vw;
                    top: .4vw;
                    font-size: 0.9vw;
                }
            }
        }
    }

    .content-box {
        background: #fff;

        .box1 {
            width: 1300px;
            margin: 0 auto;

            .tit {
                position: relative;
                line-height: 40px;
                padding-left: 20px;
                background: #e7e7e7;
                font-weight: bold;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 3px;
                    height: 100%;
                    background: #ff9123;
                    z-index: 9;
                }
            }

            .txt {
                margin-top: 10px;
                line-height: 40px;
                text-indent: 2em;
            }
        }
    }

    .img-box {
        width: 100vw;
        //height: 37vw;
    }

    .ban-dec {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        padding-top: 4vw;

        .dec-box {
            text-align: center;
            //padding: 30px;
            //background: rgba(255, 255, 255, .6);
            //backdrop-filter: blur(6px);
            //background: ;
            .dec-tit {
                display: inline-block;
                background: transparent;
                font-size: 5vw;
                letter-spacing: 5px;
                margin-bottom: 1vw;
                text-shadow: 0 0 30px rgba(0, 0, 0, .4);
            }

            .dec-dec {
                line-height: 35px;
                opacity: .7;
            }
        }
    }
}


.title-wrap {
    .more {
        position: absolute;
        right: 0;
        top: 26px;
        color: #ff9123;

        &:hover {
            color: #e06e00;
        }
    }

    .title-name {
        font-size: 30px;
        font-weight: bold;
        padding: 10px 60px;
    }
}

.plan-box {
    background: #fff;
}

.center-box {
    width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

.box2 {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .to-plan {
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .3);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        z-index: 9;
        transition: all .3s ease;
        color: #ffffff;
        font-size: 26px;
    }

    &:hover .to-plan {
        top: 0;
    }
}

.x-list {
    padding: 20px 0;
    width: 22%;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #00b2ff;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    cursor: pointer;
    transition: all .3s ease;
    //&:hover {
    //  transform: scale(1.05);
    //}
}
</style>
