<template>
    <div class="pr">
        <div class="mode-title">
            <div class="uf uf-ac uf-pc">
                <i class="el-icon-medal-1 mr-2"></i>
                <span>推荐组合</span>
            </div>
        </div>
        <div class="dec-box pr uf uf-pc" v-loading="loadingAdv" element-loading-background="transparent">
            <div>
                <div class="service-box uf">
                    <div class="item pr" :class="{on: curFlowIndex === index && selShow}" v-for="(item, index) in flowsData" :key="index" :style="{width: `calc(93vw / ${flowsData.length})`}">
                      <div class="f-list uf-f1 uf uf-pc">
                            <div class="icon-box uf uf-pc uf-ac">
                                <i :class="`iconfont icon-${item.icon}`" :style="{color: item.color}"></i>
                                <div class="f-name ml-2">{{ item.itemName }}</div>
                              <template v-if="flowsData.length > 6">
                                <div class="arrow uf uf-ac" :style="{'margin-top': '50px','margin-left': '-50px'}" v-if="index !== flowsData.length - 1">
                                  <div class="h-line"></div>
                                  <i class="iconfont icon-jiantou-you"></i>
                                </div>
                              </template>
                              <template v-else>
                                <div class="arrow uf uf-ac" :style="{'margin-top': '50px'}" v-if="index !== flowsData.length - 1">
                                  <div class="h-line"></div>
                                  <i class="iconfont icon-jiantou-you"></i>
                                </div>
                              </template>
                            </div>
                        </div>
                        <div class="li-wrap">
                            <div v-if="item.id" class="uf hi100 uf-ac uf-ver uf-pc" @click="toService(item.esProductIndex.id)">
                                <el-image fit="cover" :src="$cons.SERVER_PATH + item.esProductIndex.logoUrl" class="img">
                                    <div slot="error" class="wi100 hi100">
                                        <img :src="item.img" alt="" width="100%" height="100%"/>
                                    </div>
                                </el-image>
                                <div class="wi100 mt-2 pl-2 pr-2">
                                    <div class="fwb f18 toe-auto wi100">{{ item.esProductIndex.serviceName || '-' }}</div>
                                    <div class="uf uf-ac uf-pc op-7 wi100 mt-1">
                                        <div class="iconfont icon-company-fill"></div>
                                        <div class="ml-1 toe-auto tal" style="max-width: calc(100% - 30px)">{{ item.esProductIndex.enterpriseName || '-' }}</div>

                                    </div>
                                    <!--                                <div class="u-line-2 lh1 describe u-m-t-6">服务简介：{{ item.itemDesc || '无' }}</div>-->
                                    <!--                        <el-button type="primary" plain size="small" class="u-m-t-10">立即使用</el-button>-->
                                </div>
                            </div>
                            <div v-else class="hi100 uf uf-ver uf-pc uf-ac">
                                <img src="../../../assets/img/empty.png" alt="" width="150" />
                                <div>未选择服务</div>
                            </div>
                            <div class="opr-btn uf" v-if="item.id && curFlowIndex === index && selShow">
                                <div class="bt uf-f1 uf uf-ac uf-ver uf-pc" @click="toService(item.id)">
                                    <i class="el-icon-view"></i>
                                    <div class="mt-1">详情</div>
                                </div>
                                <div class="bt uf-f1 uf uf-ac uf-ver uf-pc" @click="deleteFlowService(index)">
                                    <i class="el-icon-delete"></i>
                                    <div class="mt-1">删除</div>
                                </div>
                            </div>
                            <div class="check-state pr" :style="{background: item.id ? 'rgb(0, 133, 55,)' : 'rgba(83, 86, 87, 0.5)'}">
                                <i class="el-icon-check"></i>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="order uf uf-ver uf-pc uf-ac" v-if="flowsData.length > 0" @click="publish" v-loading="publishLoading" element-loading-text="发布中…" element-loading-background="rgba(0, 0, 0, 0.7)">
                <i class="el-icon-right"></i>
                <div>组合发布</div>
            </div>
            <div v-if="flowsData.length === 0 && !loadingAdv" class="wi100 hi100 uf uf-pc uf-ac empty_1">
                <div class="tac">
                    <img src="../../../assets/img/empty.png" alt="" width="200"/>
                    <div class="mt-3 t">组合为空</div>
                </div>
            </div>
        </div>
<!--        <div class="sel-box" :class="{show: selShow}"  @mouseenter="selShow = true" @mouseleave="selLeave">-->
<!--            <div class="sel-wrap wi100 hi100" v-loading="loadingSel">-->
<!--                <div class="items mt-3">-->
<!--                    <div class="item uf pr" v-for="(item, index) in serviceFlowList" :key="index" @click="toService(item.id)">-->
<!--                        <div class="img">-->
<!--                            <el-image fit="cover" :src="$cons.SERVER_PATH + item.logoUrl" class="wi100 hi100">-->
<!--                                <div slot="error" class="wi100 hi100">-->
<!--                                    <img :src="item.img" alt="" width="100%" height="100%"/>-->
<!--                                </div>-->
<!--                            </el-image>-->
<!--                        </div>-->
<!--                        <div class="uf-f1 u-m-l-15 u-flow-hidden">-->
<!--                            <div class="fwb f16 toe">{{ item.serviceName || '-' }}</div>-->
<!--                            <div class="uf uf-ac company u-m-t-10">-->
<!--                                <div class="iconfont icon-company-fill"></div>-->
<!--                                <div class="uf-f1 ml-1 toe">{{ item.enterpriseName || '-' }}</div>-->
<!--                            </div>-->
<!--                            <div class="u-line-2 lh1 describe u-m-t-6">服务简介：{{ item.itemDesc || '无' }}</div>-->
<!--                            &lt;!&ndash;                        <el-button type="primary" plain size="small" class="u-m-t-10">立即使用</el-button>&ndash;&gt;-->
<!--                        </div>-->
<!--                        <div class="opr-btn2 uf">-->
<!--                            <div class="bt uf-f1 uf uf-ac uf-ver uf-pc" @click="toService(item.id)">-->
<!--                                <i class="el-icon-view"></i>-->
<!--                                <div class="mt-1">详情</div>-->
<!--                            </div>-->
<!--                            <div class="bt uf-f1 uf uf-ac uf-ver uf-pc">-->
<!--                                <i class="el-icon-circle-check"></i>-->
<!--                                <div class="mt-1">选择</div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="tac wi100 mt-4 mb-2">-->
<!--                    <el-pagination-->
<!--                        background-->
<!--                        @size-change="sizeChangeHandle"-->
<!--                        @current-change="currentChangeHandle"-->
<!--                        :current-page="pageIndex"-->
<!--                        :page-size="pageSize"-->
<!--                        :total="totalCount"-->
<!--                        layout="total, prev, pager, next, jumper">-->
<!--                    </el-pagination>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

    </div>
</template>

<script>
export default {
    name: 'trade-detail',
    props: {
        trade: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            serviceList: [],
            serviceFlowList: [],
            isDefault: false,
            loadingAdv: false,
            loadingSel: false,
            selShow: false,
            isSel: false,
            publishLoading: false,
            planList: [],
            curFlowIndex: -1,
            curFlowCode: '',
            colors: ['#409EFF', '#ff6900', '#008638', '#9200e5', '#c5a500', '#ff004c', '#409EFF', '#409EFF'],
            leftSels: [],
            flowsData: [],
            flowsSelData: [],
            curTrade: {},
            curTradeIndex: 0,
            pageIndex: 1,
            pageSize: 20,
            totalCount: 0,
            totalPage: 0,
            formData: {
                serviceComposeName: '',
                serviceComposeBatchEntityList: []
            }
        }
    },
    created() {
        this.getServiceItemList()
    },
    computed: {
        loginStatus: {
            get () { return this.$store.state.user.loginStatus },
            set (val) { this.$store.commit('user/updateLoginStatus', val) }
        }
    },
    watch: {
        trade() {
            this.getServiceItemList()
        }
    },
    methods: {
        showSelBox(index, id) {
            this.selShow = true
            this.curFlowIndex = index
            this.getServiceList()
        },
        publish() {
            if(!this.loginStatus) {
                this.$confirm('该功能需要登录，是否去登录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$router.push({name: 'login'})
                })
                return
            }
            let arr = []
            this.flowsData.forEach(v => {
                arr = arr.concat(v.sels)
            })
            if (arr.length < 1) {
                this.$message.error('请至少选择一个产业链服务项！')
                return
            }
            this.formData.serviceComposeBatchEntityList = arr
            this.$prompt('请输入组合名称', '组合名称', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputValidator: (value) => {
                    if(!value) {
                        return '名称不能为空！'
                    }
                },
                inputErrorMessage: '名称不能为空！'
            }).then(({ value }) => {
                this.publishLoading = true
                this.formData.serviceComposeName = value
                this.$http({
                    url: this.$http.adornUrl(this.$api.PUB.SERVICECOMPOSEMAIN),
                    method: 'post',
                    data: this.$http.adornData(this.formData)
                }).then(({data}) => {
                    if (data && data.code === 0) {
                        this.$message.success('发布成功！')
                        setTimeout(() => {
                            window.open('https://www.somfgs.cn/manage-back/#/product-product', '_blank')
                        },1200)
                    }
                    this.publishLoading = false
                })
            })

        },
        hideSelBox(index, id) {
            this.selShow = false
        },
        warnLogin() {
            this.$confirm('该功能需要登录，是否去登录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$router.push({name: 'login'})
            }).catch(() => {
                return false
            })
        },
        selLeave() {
            this.selShow = false
        },
        deleteFlowService(index) {
            this.serviceList[index] = {}
        },
        toService(id) {
            let page = this.$router.resolve({name: 'service', query: {id: id, isProvider: false, isBlank: true}})
            window.open(page.href, '_blank')
        },
        // 获取流程服务
        getServiceItemList() {
            this.loadingAdv = true
            // let filter = "itemCode:"+ itemCode + "*,serviceMethod:" + serviceMethod
            this.$http({
                url: this.$http.adornUrl(this.$api.CF.SERVICE_ITEM.LISTITEM),
                methods: 'get',
                params: this.$http.adornParams({
                    // 'startPage': 0,
                    // 'pageSize': 7,
                    'itemCode': Number(this.trade) + 1 > 9  ? Number(this.trade) + 1 : `0${Number(this.trade) + 1}`
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    // this.serviceList = this.handleTradeImg(data.result.list || [], 4)
                    // this.serviceList[2] = {}
                    let list = data.list.filter((rowData)=>{
                      return rowData.esProductIndex !=null && `0${Number(this.trade) + 1}` != rowData.itemCode
                    })
                    // list.pop()
                    list.forEach((v, i) => {
                        v.color = this.colors[i]
                        v.sels = []
                        if(v.esProductIndex && v.esProductIndex.id) {
                            v.esProductIndex.productName = v.esProductIndex.serviceName
                        }
                        v.sels.push(v.esProductIndex)
                    })
                    this.flowsData = this.handleTradeImg(list, 7)
                    this.$emit('isEmpty', this.flowsData.length === 0)
                    // this.getServiceList()
                }
                this.loadingAdv = false
            })
        },
        // 获取选择服务
        getServiceList() {
            this.loadingSel = true
            // let filter = "itemCode:"+ itemCode + "*,serviceMethod:" + serviceMethod
            this.$http({
                url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.SOLRLIST),
                methods: 'get',
                params: this.$http.adornParams({
                    'startPage': 0,
                    'pageSize': 8,
                    'itemCode': `0${Number(this.trade) + 1}`
                })
            }).then(({data}) => {
                // if (data && data.code === 0) {
                //     this.serviceFlowList = this.handleTradeImg(data.result.list || [], 4)
                // }
                if (data && data.code === 0) {
                    // let itemCode = localStorage.setItem('itemCode',this.dataForm.itemCode)
                    // this.dataList = data.page.list
                    // this.totalCount = data.page.totalCount
                    // this.totalPage = data.page.totalPage
                    this.serviceFlowList = this.handleTradeImg(data.result.list || [], 4)
                    this.totalCount = data.result.total || 0
                    this.totalPage = Math.ceil(data.result.total / this.pageSize) || 1
                } else {
                    this.dataList = []
                    this.totalCount = 0
                    this.totalPage = 1
                }
                this.loadingSel = false
            })
        },
        getPlanList() {
            // let filter = "itemCode:"+ itemCode + "*,serviceMethod:" + serviceMethod
            this.$http({
                url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.SOLRLIST, 'COMPONENT'),
                methods: 'get',
                params: this.$http.adornParams({
                    'startPage': 0,
                    'pageSize': 4,
                    'serviceLabel': 'REPORT',
                    'itemCode': `0${Number(this.trade) + 1}`,
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.planList = this.handleTradeImg(data.result.list || [], 4)
                }
            })
        },
        handleTradeImg(list, n) {
            if (list.length < 1) return []
            let arr = list
            arr.forEach((item, index) => {
                let num = index % n
                if (num === 0) num = n
                item.img = require(`@/assets/img/trades/${item.itemCode.slice(0,2)}/img_${num}.jpg`)
            })
            return arr
        },
        handleServiceImg(list, n) {
            if (list.length < 1) return []
            let arr = list
            let t = {}
            arr.forEach((item, index) => {
                t[`i${item.itemCode}`] = 0
            })
            arr.forEach((item) => {
                t[`i${item.itemCode}`]++
                let num = t[`i${item.itemCode}`] % n
                if (num === 0) num = n
                item.img = require(`@/assets/img/trades/${item.itemCode}/img_${num}.jpg`)
            })
            return arr
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getServiceList()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getServiceList()
        }
    }
}
</script>

<style scoped lang="scss">
.trade-detail {
    .empty_1 {
        min-height: 30vh;
        .t {
            font-size: 1vw;
            color: #fff;
            padding: 15px 20px;
            text-shadow: 0 0 10px rgba(0,0,0,.6);
            background: rgba(0,0,0,.4);
        }
    }
    .mode-title {
        width: 210px;
        line-height: 50px;
        color: white;
        background: #00a7da;
        transform: skewX(-30deg);
        text-align: center;
        transition: all 0.3s ease;
        margin: 0 auto 6vw;
        div {
            transform: skewX(30deg);
            i {
                font-size: 1.2vw;
            }
        }
    }
    margin-top: -50px;
    .switch-wrap {
        //position: sticky;
        //left: 0;
        //bottom: 10px;
        //width: 100%;
        //margin-top: -6vw;
        margin-top: 3vw;
        .switch-mode {
            width: 22vw;
            height: 3vw;
            margin: 20px auto;
            border-radius: 2vw;
            background: rgba(255, 255, 255, .8);
            backdrop-filter: blur(6px);
            color: #0d9aff;
            cursor: pointer;
            &:hover {
                background: #fff;
            }
            div {
                font-size: 1vw;
            }
            i {
                font-size: 1.2vw;
            }
        }
    }
    .check-state {
        position: absolute;
        width: 4vw;
        height: 4vw;
        border-radius: 50%;
        right: -2vw;
        top: -2vw;
        color: white;
        background: rgb(0, 133, 55);
        i {
            position: absolute;
            left: .6vw;
            bottom: .6vw;
            font-size: 1.1vw;
        }
    }
    .opr-btn {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        height: 50%;
        background: linear-gradient(bottom, transparent, rgba(255, 255, 255, .7), #fff);
        .bt {
            &:hover {
                background: #fff;
            }
            i {
                font-size: 1.6vw;
            }
            &:last-child {
                color: #d21900;
            }
        }
    }

    .order {
        width: 7vw;
        //background: rgba(0, 149, 255, 0.6);
        background: rgba(210, 25, 0, .7);
        cursor: pointer;
        transition: all .3s ease;
        color: white;
        i {
            font-size: 2vw;
        }
        div {
            font-size: 1vw;
            margin-top: 15px;
        }
        &:hover {
            background: rgba(210, 25, 0, 1);
        }
    }
    .sel-box {
        background: rgba(255, 255, 255, .8);
        backdrop-filter: blur(6px);
        //min-height: 12vw;
        max-height: 0;
        overflow: hidden;
        width: 85vw;
        margin-left: 15vw;
        transition: all .3s ease;
        .sel-wrap {
            padding: 1vw 1.5vw;
            //background: rgba(255, 255, 255, .5);
        }
    }
    .show {
        max-height: 50vw;
        //overflow: auto;
    }
    .items {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(4, 1fr);
        .item {
            background-color: #fff;
            padding: 15px;
            box-sizing: border-box;
            border-radius: 8px;
            cursor: pointer;
            overflow: hidden;
            .img {
                width: 33%;
                aspect-ratio: 1.2;
                border-radius: 8px;
                overflow: hidden;
            }

            .company {
                font-size: 14px;
                color: #333;
            }

            .describe {
                font-size: 12px;
                color: #666;
            }
            .opr-btn2 {
                position: absolute;
                left: 0;
                width: 100%;
                top: 0;
                height: 100%;
                background: linear-gradient(bottom, rgba(0, 0, 0, .4), rgba(0, 0, 0, .7));
                z-index: 999;
                transform: translateY(100%);
                transition: all .3s ease;
                //overflow: ;
                .bt {
                    color: white;
                    &:hover {
                        background: rgba(0, 0, 0, .7);
                    }
                    i {
                        font-size: 1.6vw;
                    }
                    &:last-child {
                        color: #00ceff;
                    }
                }
            }
            &:hover {
                box-shadow: 0 0 15px rgba(0, 0, 0, .2);
                .opr-btn2 {
                    height: 100%;
                    transform: translateY(0);
                }
            }
        }
    }
    .service-box {
        min-height: 10vw;
        .item {
            //background-color: #fff;
            min-height: 10vw;
            //padding: 15px;
            //width: calc(93vw / 7);
            box-sizing: border-box;
            cursor: pointer;
            border-right: 1px dashed #0d9aff;
            transition: all .3s ease;
            //overflow: hidden;
            position: relative;
            background: rgba(255, 255, 255, 0.6);
            -webkit-backdrop-filter: blur(6px);
            backdrop-filter: blur(6px);
            .arrow {
                position: absolute;
                top: -2vw;
                left: 10.5vw;
                .h-line {
                    height: 3px;
                    background: #ffdd35;
                    width: 5.1vw;
                    margin-right: -1vw;
                    box-shadow: 0 0 5px rgba(0,0,0,.4);
                }
                .iconfont {
                    font-size: 1.6vw;
                    color: #ffdd35;
                    text-shadow: 0 0 5px rgba(0,0,0,.4);
                }
            }
            .li-wrap {
                width: 100%;
                height: 100%;
                overflow: hidden;
                position: relative;
            }
            .img {
                width: 5vw;
                height: 4vw;
                border-radius: 6px;
                overflow: hidden;
            }
            &:hover {
                background: rgba(255, 255, 255, .8);
            }
            .f-list {
                position: absolute;
                top: -2.6vw;
                left: 50%;
                transform: translateX(-50%);
                z-index: 9;
                .icon-box {
                    width: 8vw;
                    height: 3.5vw;
                    background: #fff;
                    border-radius: 1vw;
                    box-shadow: 0 0 20px rgba(0,0,0,.2);
                    .iconfont {
                        font-size: 1.6vw;
                    }
                }
                .f-name {
                    font-size: 1vw;
                    font-weight: bold;
                    color: #333;
                    //margin-top: 1vw;
                }
            }
        }
        .on {
            background: rgba(255, 255, 255, .5);
        }
    }
    .left-flows {
        position: absolute;
        z-index: 9;
        left: 2vw;
        top: 10vw;
        .f-list {
            .icon-box {
                width: 8vw;
                height: 3.5vw;
                background: #fff;
                border-radius: 1vw;
                box-shadow: 0 0 20px rgba(0,0,0,.2);
                .iconfont {
                    font-size: 1.6vw;
                }
            }
            .f-name {
                font-size: 1vw;
                font-weight: bold;
                color: #333;
                //margin-top: 1vw;
            }
        }
    }
    .content-box {
        background: #fff;
        .box1 {
            width: 1300px;
            margin: 0 auto;

            .tit {
                position: relative;
                line-height: 40px;
                padding-left: 20px;
                background: #e7e7e7;
                font-weight: bold;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 3px;
                    height: 100%;
                    background: #ff9123;
                    z-index: 9;
                }
            }

            .txt {
                margin-top: 10px;
                line-height: 40px;
                text-indent: 2em;
            }
        }
    }
    .img-box {
        width: 100vw;
        //height: 37vw;
    }
    .ban-dec {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        padding-top: 4vw;
        .dec-box {
            text-align: center;
            padding-top: 3vw;
            //padding: 30px;
            //background: rgba(255, 255, 255, .6);
            //backdrop-filter: blur(6px);
            //background: ;
            .dec-tit {
                display: inline-block;
                background: transparent;
                font-size: 5vw;
                letter-spacing: 5px;
                margin-bottom: 1vw;
                text-shadow: 0 0 30px rgba(0, 0, 0, .4);
            }

            .dec-dec {
                line-height: 35px;
                opacity: .7;
            }
        }
    }
}



.title-wrap {
    .more {
        position: absolute;
        right: 0;
        top: 26px;
        color: #ff9123;

        &:hover {
            color: #e06e00;
        }
    }

    .title-name {
        font-size: 30px;
        font-weight: bold;
        padding: 10px 60px;
    }
}

.plan-box {
    background: #fff;
}

.center-box {
    width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

.box2 {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .to-plan {
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .3);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        z-index: 9;
        transition: all .3s ease;
        color: #ffffff;
        font-size: 26px;
    }

    &:hover .to-plan {
        top: 0;
    }
}

.x-list {
    padding: 20px 0;
    width: 22%;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #00b2ff;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    cursor: pointer;
    transition: all .3s ease;
    //&:hover {
    //  transform: scale(1.05);
    //}
}
</style>
