<template>
    <div class="trade-detail">
        <div class="img-box pr">
            <el-image class="wi100 hi100" :src="curTrade.bg" fit="cover"></el-image>
            <div class="ban-dec uf uf-ver">
                <template v-if="isDefault">
                    <default :trade="Number(curTradeIndex)" @isEmpty="changeEmpty"></default>
                </template>
                <template v-else>
                    <Custom :trade="Number(curTradeIndex)"></Custom>
                </template>

                <div class="switch-wrap fixed-right uf uf-pc" v-if="isDefault && !isEmpty">
                    <div class="switch-mode uf uf-pc uf-ac" @click="toCustom">
                        <div class="mr-2">自定义组合</div>
                        <i class="el-icon-right"></i>
                    </div>
                </div>
                <div v-if="!isDefault" class="switch-wrap fixed uf uf-pc">
                    <div class="switch-mode uf uf-pc uf-ac" @click="isDefault = true">
                        <i class="el-icon-back"></i>
                        <div class="ml-2">返回推荐组合</div>
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="switch-wrap uf uf-pc">-->
<!--            <div class="switch-mode uf uf-pc uf-ac" @click="switchMode">-->
<!--                <div class="mr-2">自定义组合</div>-->
<!--                <i class="el-icon-right"></i>-->
<!--            </div>-->
<!--        </div>-->
        <div class="content-box pt-3 pb-2">
            <div class="title-wrap uf uf-pc">
                <div class="title-name">行业简介</div>
            </div>
            <div class="uf uf-ac box1">
                <img src="../../assets/img/trades/img_01.png" width="400" alt=""/>
                <div class="ml-4 uf-f1 pl-2">
                    <div class="tit">服务场景</div>
                    <div class="txt">{{ curTrade.sence }}</div>
                    <div class="tit mt-3">关键技术</div>
                    <div class="txt">{{ curTrade.tech }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Default from "@/views/industry-chain/modules/default.vue"
import Custom from "@/views/industry-chain/modules/custom.vue"
export default {
    components: { Default, Custom },
    name: 'trade-detail',
    data() {
        return {
            trades: [
                {
                    name: '生物医药行业',
                    bg: require('../../assets/img/trades/shengWuYiYao.jpg'),
                    icon: 'icon-shengwuyiyao',
                    color: '#409EFF',
                    color2: '#9FDCFF',
                    router: 'index',
                    sence: '基于超算的药物筛选和研发。提供软件和算力支持的方式加快药物筛选、基因组学检测分析等进程。让以往需以月乃至年为计的运算、检测时间被缩短到几天，大大缩短药物研制时间，快速进入临床治疗。服务平台可基于超算中心的算力支撑，对接药物研发企业的研发能力和药物生产企业的生产能力。',
                    tech: '基于超算的数字化服务；面向制造服务生态体系的数据交换方法'
                },
                {
                    name: '机械装备行业',
                    bg: require('../../assets/img/trades/jiXie.jpg'),
                    icon: 'icon-jixie',
                    color: '#ff6900',
                    color2: '#ffbd73',
                    router: 'index',
                    sence: '提供装备的智能运维服务。基于智能传感设备和智能边缘计算网关等实现对装备的实时监测与预警，提供从边缘计算到工业云平台端到端的服务，达到装备安全长周期运行维护的目的。使机械装备生产企业实现服务型制造转型。',
                    tech: '基于AI+5G+IoT的云边端架构设备远程运维与故障诊断；工业海量数据管理与处理技术；面向全生命周期的制造服务数据治理。'
                },
                {
                    name: '汽车行业',
                    bg: require('../../assets/img/trades/qiChe.jpg'),
                    icon: 'icon-qiche',
                    color: '#008638',
                    color2: '#9cffd2',
                    router: 'index',
                    sence: '商用车全生命周期服务平台。例如陕西汽车“车轮滚滚”O2O服务平台，以整车产品为基础，在持续提升基本售后服务的同时，开展融资租赁、经营性租赁、商业保险、保险经纪、车联网数据服务等一系列增值服务业务。',
                    tech: '产品服务系统设计与优化技术；制造服务智能交易技术；面向制造服务生态体系的数据交换方法。'
                },
                {
                    name: '厨电行业',
                    bg: require('../../assets/img/trades/chuFang.jpg'),
                    icon: 'icon-youyanji',
                    color: '#9200e5',
                    color2: '#d19fff',
                    router: 'index',
                    sence: '提供基于传感和人工智能技术的烹饪曲线模型。例如老板电器-中式烹饪曲线的建立。已开始，通过AI人工智能精准追踪时间和温度两大核心指标，掌控每道菜式的烹饪节奏，建设中式烹饪曲线数据库，形成烹饪曲线优化，模型进一步推动厨电的智能升级，提升用户体验。',
                    tech: '基于传感和智能技术的制造和服务深度融合；基于IoT和AI的设备远程管理和维护；制造服务数据的安全隐私保护。'
                },
                {
                    name: '纺织行业',
                    bg: require('../../assets/img/trades/fangZhi.jpg'),
                    icon: 'icon-duanxiujikouyifu',
                    color: '#c5a500',
                    color2: '#c9c579',
                    router: 'index',
                    sence: '提供纺织服装全产业敏捷供应链管理，实现产业链上下游敏捷协同。上游由供应商乃至供应商的供应商组成的企业合作伙伴关系，下游由生产商、分销商、零售商、终端消费者组成的用户关系。面向供应链的统一管理，促进信息流、资金流、物流的协同整合，提升供应链整体的效率和效益。例如阿里云IoT技术帮助企业实现实时控制和智能制造，大幅提高行业供应链能力，助力服装行业整体效率水平提升。5G MES技术应用于服装行业，推动工厂侧云、边、端的数据数据闭环，最终实现服装行业产业链透明互联。秒优等合作伙伴依托服装行业工业互联网平台，形成了可快速复制的智能制造创新模式，数据驱动整个服装生产流程，通过系统集成、精益管理、IoT技术、智能排产、智能在线调度、数字化绩效手段，将原本大批量生产模式转化为连续的、小批量、个性化、高效益的生产模式，真正做到快速精准去库存，实现小单快返的终极目标。',
                    tech: '网络驱动的制造产品服务化设计；制造服务智能交易技术。'
                },
                {
                    name: '包装行业',
                    bg: require('../../assets/img/trades/baoZhuang.jpg'),
                    icon: 'icon-wuliaobaozhuangdan',
                    color: '#409EFF',
                    color2: '#9FDCFF',
                    router: 'index',
                    sence: '提供基于AI+AR的包装定制化设计服务。提供具有专业包装设计能力的人工智能和增强现实服务，客户只需在包装商城上输入包装设计需求，就会自动快速提供若干种方案供其选择，并且能呈现最终的三维立体、增强现实效果。具有强大的学习能力，学习用户习惯、市场偏好等等，这些都将成为它的“知识储备”，更好地为用户提供包装设计服务。',
                    tech: '基于数字孪生和工业互联网的数字化服务技术；产品与服务系统设计优化方法。'
                },
                {
                    name: '家具行业',
                    bg: require('../../assets/img/trades/jiaJu.jpg'),
                    icon: 'icon-shafa',
                    color: '#ff6900',
                    color2: '#ffbd73',
                    router: 'index',
                    sence: '提供易简大规模个性化定制服务模式，实现“先设计，再销售，后生产”。破解定制家居生产的周期长、出错率高、品质差和规模生产难的难题，实现了规模个性化设计、大规模个性化定制与大规模个性化服务，有效把制造与服务融合，为消费者提供一站式的全新的定制家居消费体验。',
                    tech: '基于数字孪生和工业互联网的数字化服务技术；基于人工智能技术的制造和服务深度融合。'
                },
                {
                    name: '生鲜食品行业',
                    bg: require('../../assets/img/trades/shengXian.jpg'),
                    icon: 'icon-shuiguo',
                    color: '#008638',
                    color2: '#9cffd2',
                    router: 'index',
                    sence: '提供冷链物流全过程、全周期服务，实现冷链全过程监测与追溯，检验检疫服务。例如阿里的盒马鲜生。盒马运用大数据、移动互联、智能物联网、自动化等技术及先进设备，实现人、货、场三者之间的最优化匹配，平台拥有从供应链、仓储到配送的完整物流体系。',
                    tech: '网络驱动的产品服务化设计；服务数据交换方法、定价模型和智能交易技术。'
                },
                {
                    name: '电梯行业',
                    bg: require('../../assets/img/trades/dianTi.jpg'),
                    icon: 'icon-dianti-xiantiao',
                    color: '#9200e5',
                    color2: '#d19fff',
                    router: 'index',
                    sence: '提供电梯设备预测性维护、设备全生命周期管理以及提供可信的透明监管和全链路的安全保障。例如，通过工业互联网的规则引擎与阿里云其他云产品打通，实现采集+计算+存储+AI+可信的全栈服务，充分挖掘物联网数据的价值，实现连接到智能的跨越；通过构建数据平台，提供设备预测与分析统计服务，可以实现电梯设备预测性维护、设备全生命周期管理以及提供可信的透明监管和全链路的安全保障。基于可信电梯物联网平台实施的垂直电梯远程监测解决方案，采用不触碰电梯本身的安装方式，快速适配老旧电梯，采集上报各种数据，比如电梯运行状态信息，是否有人信息、所处楼层信息、实时故障等信息，接入到远程监控系统。结合后台数据为客户提供故障分析服务以及故障处理救援服务、智能维护服务等。',
                    tech: '基于传感和智能技术的制造和服务深度融合；基于IoT和AI的设备远程管理和维护；全生命周期的制造服务数据治理。'
                },
                {
                    name: '精密模具行业',
                    bg: require('../../assets/img/trades/muJu.jpg'),
                    icon: 'icon-yewumoban',
                    color: '#409EFF',
                    color2: '#9FDCFF',
                    router: 'index',
                    sence: '采用“平台+小微”方式，实现“共享制造”。例如深圳市五鑫科技的“Mould Lao众创空间”，以赋能的方式帮助平台内小微企业及各个技术创客实现从被动劳动变成自主竞价模式下的技能共享。小微企业的每笔订单，以内部市场竞标的方式由小微及创客自主报价，相互竞标。竞标成功后，平台通过共享制造系统对每个创客的工作进度和制造品质进行实时跟踪，他们完成一天的工作后可以立即扫码结算。平台将大量小微企业整合到一起，通过大数据、人工智能等技术，使供需之间能够实现智能化高效匹配。',
                    tech: '服务数据交换方法、定价模型和智能交易技术；网络驱动的协同制造技术。'
                }
            ],
            serviceList: [],
            serviceFlowList: [],
            isDefault: true,
            isEmpty: false,
            loadingAdv: false,
            loadingSel: false,
            selShow: false,
            isSel: false,
            planList: [],
            curFlowIndex: -1,
            colors: ['#409EFF', '#ff6900', '#008638', '#9200e5', '#c5a500', '#ff004c', '#409EFF', '#409EFF'],
            flowsData: [],
            xqList: [
                {name: '寻求马铃薯自动脱皮技术', url: require('../../assets/img/index/img_3.jpg')},
                {name: '汽车物流运输提质增效', url: require('../../assets/img/index/img_4.jpg')},
                {name: '光伏组件供应', url: require('../../assets/img/index/img_5.jpg')},
                {name: '寻求基于智能化、信息化的印刷..', url: require('../../assets/img/index/img_6.jpg')}
            ],
            curTrade: {},
            curTradeIndex: 0,
            tableList: [
                {
                    id: 1,
                    imageSrc: 'https://n.sinaimg.cn/sinakd20211013s/141/w1080h661/20211013/9fa7-f99dd31a6c7f91bb477a0b88764989e1.jpg',
                    title: '基因组学检测分析',
                    company: '北京生物科技有限公司',
                    describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
                },
                {
                    id: 2,
                    imageSrc: 'https://www.lvchengsidai.com/wp-content/uploads/2022/01/medoddhu.jpg',
                    title: '药物临床实验与应用',
                    company: '北京生物科技有限公司',
                    describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
                },
                {
                    id: 3,
                    imageSrc: 'https://n.sinaimg.cn/sinakd20211013s/141/w1080h661/20211013/9fa7-f99dd31a6c7f91bb477a0b88764989e1.jpg',
                    title: '基因组学检测分析',
                    company: '北京生物科技有限公司',
                    describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
                },
                {
                    id: 4,
                    imageSrc: 'https://www.lvchengsidai.com/wp-content/uploads/2022/01/medoddhu.jpg',
                    title: '药物临床实验与应用',
                    company: '北京生物科技有限公司',
                    describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
                },
                {
                    id: 5,
                    imageSrc: 'https://n.sinaimg.cn/sinakd20211013s/141/w1080h661/20211013/9fa7-f99dd31a6c7f91bb477a0b88764989e1.jpg',
                    title: '基因组学检测分析',
                    company: '北京生物科技有限公司',
                    describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
                },
                {
                    id: 6,
                    imageSrc: 'https://www.lvchengsidai.com/wp-content/uploads/2022/01/medoddhu.jpg',
                    title: '药物临床实验与应用',
                    company: '北京生物科技有限公司',
                    describe: '通过对原始数据的采集、清洗和处理，建立数据模型。'
                }
            ],
            pageIndex: 1,
            pageSize: 20,
            totalCount: 0,
            totalPage: 0
        }
    },
    computed: {
        loginStatus: {
            get () { return this.$store.state.user.loginStatus },
            set (val) { this.$store.commit('user/updateLoginStatus', val) }
        }
    },
    created() {
        this.curTrade = this.trades[this.$route.query.trade || 0]
        this.curTradeIndex = this.$route.query.trade || 0
        // this.getServiceItemList()
        // this.getFlowServiceList()
        // this.getPlanList()
    },
    watch: {
        $route(val) {
            this.curTrade = this.trades[val.query.trade]
            this.curTradeIndex = this.$route.query.trade || 0
            this.isDefault = true
            this.getPlanList()
        },
        loginStatus(val) {
            if(!val) this.isDefault = true
        }
    },
    methods: {
        changeEmpty(val) {
            this.isEmpty = val
        },
        showSelBox(index, id) {
            this.selShow = true
            this.curFlowIndex = index
            this.getServiceList()
        },
        toCustom() {
            if(this.loginStatus) {
                this.isDefault = false
            } else {
                this.warnLogin()
            }
        },
        warnLogin() {
            this.$confirm('该功能需要登录，是否去登录?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$router.push({name: 'login'})
            }).catch(() => {
            })
        },
        hideSelBox(index, id) {
            this.selShow = false
        },
        selLeave() {
            this.selShow = false
        },
        deleteFlowService(index) {
            this.serviceList[index] = {}
        },

        toService(id) {
            //
            this.$router.push({name: 'service', query: {id: id, isProvider: false}})
        },
        toPlan() {
            window.open(`${this.$cons.COMPONENT_PATH}/#/plan-list?search=&trade=${Number(this.curTradeIndex)}&itemCode=0${Number(this.curTradeIndex) + 1}`, '_blank')
        },
        getPlanList() {
            // let filter = "itemCode:"+ itemCode + "*,serviceMethod:" + serviceMethod
            this.$http({
                url: this.$http.adornUrl(this.$api.PRODUCT.SERVICE.SOLRLIST, 'COMPONENT'),
                methods: 'get',
                params: this.$http.adornParams({
                    'startPage': 0,
                    'pageSize': 4,
                    'serviceLabel': 'REPORT',
                    'itemCode': `0${Number(this.curTradeIndex) + 1}`,
                })
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.planList = this.handleTradeImg(data.result.list || [], 4)
                }
            })
        },
        handleTradeImg(list, n) {
            if (list.length < 1) return []
            let arr = list
            let t = {}
            arr.forEach((item, index) => {
                t[`i${item.itemCode}`] = 0
            })
            arr.forEach((item) => {
                t[`i${item.itemCode}`]++
                let num = t[`i${item.itemCode}`] % n
                if (num === 0) num = n
                item.img = require(`@/assets/img/trades/${item.itemCode}/img_${num}.jpg`)
            })
            return arr
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val
            this.pageIndex = 1
            this.getServiceList()
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val
            this.getServiceList()
        }
    }
}
</script>

<style scoped lang="scss">
.trade-detail {
    .mode-title {
        width: 210px;
        line-height: 40px;
        color: white;
        background: #E87600;
        transform: skewX(-30deg);
        text-align: center;
        transition: all 0.3s ease;
        margin: 0 auto 4vw;
        div {
            transform: skewX(30deg);
            i {
                font-size: 1.2vw;
            }
        }
    }
    margin-top: -50px;
    .switch-wrap {
        //bottom: 10px;
        //margin-top: 3vw;
        .switch-mode {
            width: 22vw;
            height: 3vw;
            margin: 20px auto;
            border-radius: 2vw;
            background: rgba(255, 255, 255, .8);
            backdrop-filter: blur(6px);
            color: #0d9aff;
            cursor: pointer;
            &:hover {
                background: #fff;
            }
            div {
                font-size: 1vw;
            }
            i {
                font-size: 1.2vw;
            }
        }
    }
    .fixed-right {
        position: absolute;
        top: 2.5vw;
        right: -3vw;
    }
    .fixed {
        position: absolute;
        top: 2vw;
        left: -3vw;
        .switch-mode {
            width: 18vw;
            height: 3vw;
            margin: 20px auto;
            border-radius: 2vw;
            background: rgba(0, 0, 0, .6);
            backdrop-filter: blur(6px);
            color: #ffffff;
            cursor: pointer;
            &:hover {
                background: #000;
            }
            div {
                font-size: 1vw;
            }
            i {
                font-size: 1.2vw;
            }
        }
    }
    .check-state {
        position: absolute;
        width: 4vw;
        height: 4vw;
        border-radius: 50%;
        right: -2vw;
        top: -2vw;
        color: white;
        background: rgb(0, 133, 55);
        i {
            position: absolute;
            left: .6vw;
            bottom: .6vw;
            font-size: 1.1vw;
        }
    }
    .opr-btn {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        height: 50%;
        background: linear-gradient(bottom, transparent, rgba(255, 255, 255, .7), #fff);
        .bt {
            &:hover {
                background: #fff;
            }
            i {
                font-size: 1.6vw;
            }
            &:last-child {
                color: #d21900;
            }
        }
    }

    .order {
        padding: 0 40px;
        //background: rgba(0, 149, 255, 0.6);
        background: rgba(210, 25, 0, .7);
        cursor: pointer;
        transition: all .3s ease;
        color: white;
        i {
            font-size: 2vw;
        }
        div {
            font-size: 1vw;
            margin-top: 15px;
        }
        &:hover {
            background: rgba(210, 25, 0, 1);
        }
    }
    .sel-box {
        background: rgba(255, 255, 255, .6);
        backdrop-filter: blur(6px);
        //min-height: 12vw;
        max-height: 0;
        overflow: hidden;
        width: 90%;
        margin: 0 auto;
        transition: all .3s ease;
        .sel-wrap {
            padding: 1vw 1.5vw;
            background: rgba(255, 255, 255, .5);
        }
    }
    .show {
        max-height: 50vw;
        //overflow: auto;
    }
    .items {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(4, 1fr);
        .item {
            background-color: #fff;
            padding: 15px;
            box-sizing: border-box;
            border-radius: 8px;
            cursor: pointer;
            overflow: hidden;
            .img {
                width: 33%;
                aspect-ratio: 1.2;
                border-radius: 8px;
                overflow: hidden;
            }

            .company {
                font-size: 14px;
                color: #333;
            }

            .describe {
                font-size: 12px;
                color: #666;
            }
            .opr-btn2 {
                position: absolute;
                left: 0;
                width: 100%;
                top: 0;
                height: 100%;
                background: linear-gradient(bottom, rgba(0, 0, 0, .4), rgba(0, 0, 0, .7));
                z-index: 999;
                transform: translateY(100%);
                transition: all .3s ease;
                //overflow: ;
                .bt {
                    color: white;
                    &:hover {
                        background: rgba(0, 0, 0, .7);
                    }
                    i {
                        font-size: 1.6vw;
                    }
                    &:last-child {
                        color: #00ceff;
                    }
                }
            }
            &:hover {
                box-shadow: 0 0 15px rgba(0, 0, 0, .2);
                .opr-btn2 {
                    height: 100%;
                    transform: translateY(0);
                }
            }
        }
    }
    .service-box {
        min-height: 10vw;
        .item {
            //background-color: #fff;
            min-height: 10vw;
            //padding: 15px;
            width: calc(100% / 7);
            box-sizing: border-box;
            cursor: pointer;
            border-right: 1px dashed #0d9aff;
            transition: all .3s ease;
            overflow: hidden;
            .img {
                width: 5vw;
                height: 4vw;
                border-radius: 6px;
                overflow: hidden;
            }
            &:hover {
                background: rgba(255, 255, 255, .5);
            }
        }
        .on {
            background: rgba(255, 255, 255, .5);
        }
    }
    .flows {
        position: absolute;
        z-index: 9;
        top: -2.5vw;
        .f-list {
            .icon-box {
                width: 8vw;
                height: 3.5vw;
                background: #fff;
                border-radius: 1vw;
                box-shadow: 0 0 20px rgba(0,0,0,.2);
                .iconfont {
                    font-size: 1.6vw;
                }
            }
            .f-name {
                font-size: 1vw;
                font-weight: bold;
                color: #333;
                //margin-top: 1vw;
            }
        }
    }
    .content-box {
        background: #fff;
        .box1 {
            width: 1300px;
            margin: 0 auto;

            .tit {
                position: relative;
                line-height: 40px;
                padding-left: 20px;
                background: #e7e7e7;
                font-weight: bold;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 3px;
                    height: 100%;
                    background: #ff9123;
                    z-index: 9;
                }
            }

            .txt {
                margin-top: 10px;
                line-height: 40px;
                text-indent: 2em;
            }
        }
    }
    .img-box {
        width: 100vw;
        min-height: 37vw;
    }
    .ban-dec {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        padding-top: 4vw;
        .dec-box {
            text-align: center;
            //padding: 30px;
            background: rgba(255, 255, 255, .6);
            backdrop-filter: blur(6px);
            //background: ;
            .dec-tit {
                display: inline-block;
                background: transparent;
                font-size: 5vw;
                letter-spacing: 5px;
                margin-bottom: 1vw;
                text-shadow: 0 0 30px rgba(0, 0, 0, .4);
            }

            .dec-dec {
                line-height: 35px;
                opacity: .7;
            }
        }
    }
}



.title-wrap {
    .more {
        position: absolute;
        right: 0;
        top: 26px;
        color: #ff9123;

        &:hover {
            color: #e06e00;
        }
    }

    .title-name {
        font-size: 30px;
        font-weight: bold;
        padding: 10px 60px;
    }
}

.plan-box {
    background: #fff;
}

.center-box {
    width: 1300px;
    margin-left: auto;
    margin-right: auto;
}

.box2 {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .to-plan {
        position: absolute;
        top: -100%;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .3);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        z-index: 9;
        transition: all .3s ease;
        color: #ffffff;
        font-size: 26px;
    }

    &:hover .to-plan {
        top: 0;
    }
}

.x-list {
    padding: 20px 0;
    width: 22%;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #00b2ff;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);
    cursor: pointer;
    transition: all .3s ease;
    //&:hover {
    //  transform: scale(1.05);
    //}
}
</style>
